
.fixed-btn {
    width: 50px !important;
    height: 50px !important;
    border-radius: 10px !important;
    -webkit-transition: all ease-out .3s; -ms-transition: all ease-out .3s; transition: all ease-out .3s;
    box-shadow: 0 0 10px var(--shadow) !important;
}
.fixed-btn.active{
    margin-bottom: 56px;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .fixed-btn.active{
        margin-bottom: 70px;
    }
    .fixed-btn.bottom{
        bottom:246px;
    }
}
@media (min-width:1024px){
    .fixed-btn{
        width: 60px !important;
        height: 60px !important;
        border-radius: 16px !important;
    }
}
