:root{
    --container: 1200px;
    --container-lg: 1600px;

    --border-color: #e6e6e6;
    --border-color-dark: #7e7e7e;

    --shadow: rgba(17,41,81,.1);

    --font-pre: "Pretendard","NotoSansKR","Nanum Gothic","Apple SD Gothic Neo",Helvetica,Arial,sans-serif;
    --font-notosans: "NotoSansKR","Pretendard","Nanum Gothic","Apple SD Gothic Neo",Helvetica,Arial,sans-serif;

	--input-height: 40px;
	--input-large-height: 48px;
    --input-border-radius: 8px;
    --input-border-color: #ddd;
    --input-border-hover: #ddd;
    --input-border-focus: #7e7e7e;

    --primary-gra:var(--v-primary-base), var(--v-secondary-base);
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
	:root{
		--input-height: 48px;
	    --input-large-height: 60px;
	}
}
@media (min-width:1200px){
}