
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";

.sub-visual{
    padding: 60px 0 0;
    margin-top:-60px;
}
.sub-visual__wrapper{
    width: 100%;
    max-width: calc(var(--container) + 24px);
    margin: auto;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .sub-visual{
        padding: 80px 0 0;
        margin-top:-80px;
    }
}
@media (min-width:1024px){
    .sub-visual{
        padding: 120px 0 0;
        margin-top:-120px;
    }
}
@media (min-width:1200px){
}
